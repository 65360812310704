body {
  margin: 0;
  color: #666666;
}

/* @font-face {
  font-family: 'HelveticaLTStd-Blk';
  src: local('HelveticaLTStd-Blk'), url(./fonts/HelveticaLTStd-Blk.woff) format('woff');
} */


.tabs-component [role="tablist"] {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dee2e6;
  position: relative;
}

.tabs-component [role="tablist"] button {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  background-color: #fff;
  transition: border 0.2s ease-in-out 0s;
}

.tabs-component [role="tablist"] button.active,
.tabs-component [role="tablist"] button:focus,
.tabs-component [role="tablist"] button:hover {
  border-width: 1px;
  border-color: #e9ecef #e9ecef #b41c1c;
}

.tabs-component [role="tablist"] button.active {
  color: #495057;
  background-color: #C4C4C4;
}

.tabs-component [role="tabpanel"] {
  text-align: left;
  padding: 1rem;
  background-color: #fff;
  /**box-shadow: 1px 1px 2px rgb(204 204 204 / 75%);**/
}

@media screen and (max-width: 480px) {
  .tabs-component [role="tabpanel"] {
    padding: 0;
  }
}

.tabs-component.vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component.vertical [role="tablist"] {
  flex-direction: column;
  width: 20%;
}

.tabs-component.vertical [role="tabpanel"] {
  flex: 1;
}

.tabs-component.vertical [role="tablist"] button {
  height: 4em;
  text-align: initial;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.tabs-component [role="tablist"] button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.tabs-component.vertical [role="tablist"] button.active,
.tabs-component.vertical [role="tablist"] button:focus,
.tabs-component.vertical [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
}


.htmlContainer img {
  object-fit: contain;
  height: auto !important;
  width: 480px !important;
}

.htmlContainer p {
  margin: 1em 0;
  padding: 0 6px;
}

.trTitle {
  /* padding: 0 1em 0 0; */
  font-weight: 700;
  line-height: 19.2px;
}

.thCenterAlign {
  text-align: center;
}


.emailIcons {
  text-decoration: none;
  margin: 8px 0;
}


@media (max-width: 991px) {
  .trTitle {
    width: 50%;
  }

  .emailHash {
    font-size: 13px;
  }
}


input[type='file'] {
  color: rgba(0, 0, 0, 0)
}

.formInput {
  /* border-radius: 5px; */
  width: 200px !important;
  border: 1px solid #C4C4C4;
  font-size: 14px;
  height: 32px;
  margin: 10px 0;
}

.formInputDate {
  width: 200px !important;
  border: 1px solid #C4C4C4;
  font-size: 14px;
  height: 32px;
  margin: 10px 0;
  padding-left: 8px;
}

.formInputProfile {
  /* border-radius: 5px; */
  /* width: 200px !important; */
  border: 1px solid #C4C4C4;
  font-size: 14px;
  height: 32px;
  margin: 10px 0;
}

.formInputVerification {
  border: 1px solid #C4C4C4;
  font-size: 14px;
  height: 32px;
  margin: 10px 0;
  padding: 0 8px;
  width: 100%;
}

.formTextArea {
  /* border-radius: 5px; */
  border: 1px solid #C4C4C4;
  font-size: 14px;
}

.formTextAreaDesc {
  /* border-radius: 5px; */
  border: 1px solid #C4C4C4;
  background-color: #e5fbf3;
  font-size: 14px;
}

.wholetableBorder {
  border: 1px solid #c4c4c4;
}

.tableBorder {
  border: 1px solid #c4c4c4;
  padding: .5em;
}

.linkText {
  font-weight: bold;
  cursor: pointer;
  color: #110ED4;
}

h2 {
  font-family: helvetica;
}

/** PAGINATOR STYLING**/
[class*="css-"][class$="-pagination"]{
  text-align: right !important;
}
.pagination-button-wrapper {

}

.pagination-button {

}

.pagination-page-info {
  margin: 0 .5em;
}

/** END OF PAGINATOR STYLING**/


/** Reactive search **/
  .result-list-container {
    width: 100% !important;
    padding: 0;
  }
  .css-yw1mgn-Flex-leftLabel {
    padding: 0;
  }

  @media (max-width: 480px) {
    .result-list-container {
      padding: 0 8px;
    }
  }
/** End of Reactive search **/


/*
  Chrome, Safari, Edge, Opera
  Remove up down arrow in input type number
*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="date"]
{
  display:block;
  /* Solution 1 */
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;

  /* Solution 2 */
  /* min-width: 96%; */
}


/* Home iframe video */
.iframeStyling {
  position: absolute;
  top: 20%;
  left: 20%;
  bottom: 0;
  right: 0;
  width: 60%;
  height: 60%;
  border: none;
}
@media (max-width: 991px) {
  .iframeStyling {
    top: 10%;
    left: 10%;
    bottom: 0;
    right: 0;
    width: 80%;
    height: 80%;
  }
}

@media (max-width: 480px) {
  .iframeStyling {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
/* End of iframe video */


/**
  BOOTSTRAP TABLE STYLING
**/

.table {
width: 100%;
margin-bottom: 1rem;
/* color: #FAFAFA; */
}

.table th,
.table td {
padding: 0.75rem;
vertical-align: top;
//border-top: 1px solid #dee2e6;
}

.table thead th {
vertical-align: bottom;
//border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
//border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
padding: 0.3rem;
}

.table-bordered {
border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
//background-color: rgba(0, 0, 0, 0.05);
  background-color: #59C1BD;
}

.table-striped tbody tr:nth-of-type(even) {
//background-color: rgba(0, 0, 0, 0.05);
  color: #666;
}

.table-hover tbody tr:hover {
color: #212529;
background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
border-color: #7abaff;
}

.table-hover .table-primary:hover {
background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
border-color: #8fd19e;
}

.table-hover .table-success:hover {
background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
border-color: #86cfda;
}

.table-hover .table-info:hover {
background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
border-color: #ed969e;
}

.table-hover .table-danger:hover {
background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
border-color: #fbfcfc;
}

.table-hover .table-light:hover {
background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
border-color: #95999c;
}

.table-hover .table-dark:hover {
background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
color: #fff;
background-color: #343a40;
border-color: #454d55;
}

.table .thead-light th {
color: #495057;
background-color: #e9ecef;
border-color: #dee2e6;
}

.table-dark {
color: #fff;
background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
border-color: #454d55;
}

.table-dark.table-bordered {
border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
color: #fff;
background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
.table-responsive-sm {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.table-responsive-sm > .table-bordered {
border: 0;
}
}

@media (max-width: 767.98px) {
.table-responsive-md {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.table-responsive-md > .table-bordered {
border: 0;
}
}

@media (max-width: 991.98px) {
.table-responsive-lg {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.table-responsive-lg > .table-bordered {
border: 0;
}
}

@media (max-width: 1199.98px) {
.table-responsive-xl {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.table-responsive-xl > .table-bordered {
border: 0;
}
}

.table-responsive {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
border: 0;
}

.table-responsive {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
border: 0;
}


.search-form-control {
  padding: 8px;
  border-radius: 4px;
  width: 25%;
  margin: 8px;
}

/**
  END OF BOOTSTRAP TABLE STYLING
**/


/*
  Homepage search input
*/

.search_wrapper {
  position:relative;
  margin-bottom: 0;
}

.search_icon {
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px 4px 4px 8px;
  position: absolute;
  box-sizing:border-box;
  top:50%;
  left:2px;
  transform: translateY(-50%);
}

.search_input {
  height: 50px;
  width: 90%;
  color: #fff;
  padding-left: 2.5rem;
  border-radius: 16px;
  background-color: transparent;
  border: 1px solid #fff;
}

@media (max-width: 1024px) {
    .search_wrapper {
      margin-bottom: 1em;
    }
    .search_input {
      height: 40px;
      width: 100%;
    }
}

/**
  Suspense image css
**/
.footerIcon {
  width: 22px;
  height: 22px;
  margin: 12px 12px 0 0;
}

.dailaiHomeLogo {
  margin: 1em 0 0 0;
  width: 280px;
}

.dailaiLogo {
  margin: 0;
}

@media (max-width: 480px) {
  .dailaiHomeLogo {
    width: 150px;
  }
}


/**
  Search all text field Post list
**/
.searchAllInputField {
  padding-bottom: 2.5em;
  width: 50%;
  border-radius: 5px;
  padding-left: 0;
}

@media (max-width: 480px) {
  .searchAllInputField {
    width: 100%;
    padding-left: 8px;
  }
}

.date_range_component .DayPickerInput input {
  font-size: 0.75rem !important;
}


/**
  Reactivesearch styling
**/
.filter-search-input {
  border-radius: 8px;
  border: 1px solid blue;
}

.custom-datasearch-container-mt {
  border-top: 1px solid #ccc;
}

.custom-datasearch-container-mt .css-3cr1qg-suggestionsContainer .input-group {
  box-shadow: none !important;
}

.custom-datasearch-container .css-3cr1qg-suggestionsContainer .input-group {
  box-shadow: none !important;
}

.custom-datasearch-input {
  border: 0 !important;
  background-color: transparent !important;
  color: #4FA495;
}

.custom-datasearch-input-mt {
  border: 0 !important;
  border-top: 1px solid #ccc;
  background-color: #fff !important;
}
